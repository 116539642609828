<!--
 * @Author       : Hugo
 * @Date         : 2022-01-04 11:05:53
 * @LastEditTime : 2022-01-05 14:44:59
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Putaway/FilterBar.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_putaway_filter_bar bbox d-flex justify-space-between align-center un_sel"
  >
    <div class="btn_group d-flex align-center"
      v-show="!switch_bantch"
    >
      <div class="button cp"
        :class="{active: show_flag === ''}"
        @click.stop="changeShowflag('')"
      >全部<span
        v-show="all_num !== undefined"
      >({{all_num}})</span></div>
      <div class="button cp"
        :class="{active: show_flag === '1'}"
        @click.stop="changeShowflag('1')"
      >已上架<span
        v-show="putaway_num !== undefined"
      >({{putaway_num}})</span></div>
      <div class="button cp"
        :class="{active: show_flag === '0'}"
        @click.stop="changeShowflag('0')"
      >未上架<span
        v-show="sold_out_num !== undefined"
      >({{sold_out_num}})</span></div>
    </div>
    <div class="fun_group d-flex align-center "
      v-show="show_flag === '0'"
    >
      <template
        v-if="switch_bantch"
      >
        <div class="button putaway dark"
          @click.stop="putawayByBantch();"
        >上架至商城</div>
        <div class="button all mr-10"
          @click.stop="touchSelectAll();"
        >{{is_all ? '取消' : ''}}全选</div>
        <div class="button cancel"
          @click.stop="closeBantch();"
        >取消批量</div>
      </template>
      <div class="button batch"
        v-if="!switch_bantch"
        @click.stop="openBantch()"
      >批量处理</div>
    
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show_flag: {
      type: String,
      default: '',
    },
    is_all: {
      type: Boolean,
      default: false,
    },
    all_num: {
      type: Number,
      default: undefined,
    },
    putaway_num: {
      type: Number,
      default: undefined,
    },
    sold_out_num: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
  },
  data(){
    return {
      switch_bantch: false,
    }
  },
  methods: {
    changeShowflag(showflag){
      const {show_flag} = this;
      if(show_flag === showflag){
        return;
      }
      this.$emit('handleChange', showflag);
    },
    /**
     * 开启批量处理
     */
    openBantch(){
      this.switch_bantch = true;
      this.$emit('handleOpenBantch');
    },
    /**
     * 关闭批量处理
     */
    closeBantch(){
      this.switch_bantch = false;
      this.$emit('handleCloseBantch');
    },
    touchSelectAll(){
      this.$emit('handleSelectAll');
    },
    /**
     * 批量上架
     */
    putawayByBantch(){
      this.$emit('handlePutawayByBantch');
    }
  }
}
</script>

<style lang="scss" scoped>
.components_putaway_filter_bar{
  .btn_group{
    .button{
      @include transition;
      font-size: 14px;
      color: #696969;
      border-right: 2px solid #eee;
      padding: {
        left: 10px;
        right: 10px;
      }
      &.active{
        color: $main_blue;
      }
      &:last-child{
        border-right: none;
      }
      &:hover{
        color: $main_blue;
      }
    }
  }
  .fun_group{
    .button{
      line-height: 38px;
      background: #F8F8F8;
      border-radius: 4px;
      border: 1px solid #DDDDDD;
      margin-left: 10px;
      font-size: 14px;
      color: #666666;
      padding: {
        left: 10px;
        right: 10px;
      }
      &.dark{
        color: #fff;
        background-color: $main_blue;
      }
    }
  }
}
</style>
