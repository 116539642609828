<!--
 * @Author       : Hugo
 * @Date         : 2020-07-29 16:55:53
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-09-27 14:08:07
 * @Description  : 添加标签
 * @FilePath     : \miaohang\src\components\FunTemplate\AddTags.vue
--> 
<template>
  <div class="template_add_tags_wrapper">
    <div class="title_group">
      <div class="title">设置标签</div>
      <div class="close cp" @click="$emit('closeTagBox')"></div>
    </div>
    <div class="content_group">
      <!-- 固定展示 -->
      <div class="fixed_display">
        <div class="search_group">
          <div class="search_box" :class="{on: input_on || input_list.length != 0}">
            <div class="search_tag" v-for="(item, index) in input_list" :key="`input_list${index}`">{{item.tagName}}</div>
            <input type="text" class="search" placeholder="请输入标签，Enter确认, 多个标签用逗号分隔..." v-model="input" @keyup="inputKeyup" ref="input" @focus="input_on=true;" @blur="input_on=false;">
          </div>
        </div>
     
        <div class="selected_group tag_group" v-if="(template_addtag_unit_data && template_addtag_unit_data.tags && template_addtag_unit_data.tags.length) || append_tag_list.length">
          <div class="tag_title">当前模板标签</div>
          <div class="tag_box">
            <template v-if="tag_list && tag_list.length">
                
              <div class="tag" v-for="(item,index) in tag_list" :key="`tag_list${index}`">
                {{item.tagName}}
                <div class="close" @click="deleteTagHandler(item)"></div>
              </div>
            </template>
            <template v-if="append_tag_list && append_tag_list.length">
                
              <div class="tag" v-for="(item,index) in append_tag_list" :key="`append_tag_list${index}`">
                {{item.tagName}}
                <div class="close" @click="deleteTagHandler(item)"></div>
              </div>
            </template>
          </div>
        </div>
        <div class="recently_group tag_group" v-if="0">
          <div class="tag_title">最近使用标签...</div>
            <div class="tag_box">
              <div class="tag cp">
                vivo标签
              </div>
            </div>
        </div>
      </div>
      <div class="all_group tag_group">
        <div class="tag_title">全部标签...</div>
        <div class="tag_box" >
          <div
            class="tag cp"
            :class="{on: item.select}"
            v-for="(item,index) in all_tag_list"
            :key="`all_tag_list${index}`"
            @click="linkageClickHandler(item, index)"
          >
            {{item.tagName}}
          </div>
        </div>
      </div>
    </div>
    <div class="fun_group">
      <div class="fun cp sure" @click="addTags">确定</div>
      <div class="fun cp cancel" @click="$emit('closeTagBox')">取消</div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    template_my_tags() {
      let val = this.$tools.deepClone(this.$store.state.template.template_my_tags);
      if(val){
        val = this.$tools.deepClone(val);
        val.forEach( item => {
          item.select = false;
        })
        this.$set(this, 'all_tag_list', val);
      }
      return val;
    },
    template_addtag_unit_data(){
      let val = this.$tools.deepClone(this.$store.state.template.template_addtag_unit_data);
      if(val.tags){
        let tag_list = this.$tools.deepClone(val.tags);
        this.$set(this, 'tag_list', tag_list);
      }
      return val;
    },
    initial_tag_data(){
      let {all_tag_list, tag_list} = this;
      if(all_tag_list && tag_list){
        if( tag_list&& tag_list.length){
          tag_list.forEach( item => {
            let aim_index = all_tag_list.findIndex( subitem => {
              return item.tagName == subitem.tagName;
            })
            this.$set(this.all_tag_list[aim_index], 'select', true);
          })

        }
      }
      return {
        all_tag_list,
        tag_list,
      }
    }
    
  },
  watch:{
    template_my_tags:{
      handler: function(val){
        // // console.log(val,'all_tag_list');
        if(val){
          val = this.$tools.deepClone(val);
          val.forEach( item => {
            item.select = false;
          })
          this.$set(this, 'all_tag_list', val);
        }
      },
      deep: true,
    },
    template_addtag_unit_data:{
      handler: function(val){
        if(val.tags){
          let tag_list = this.$tools.deepClone(val.tags);
          this.$set(this, 'tag_list', tag_list);
        }
      },
      deep: true,
    },
    // 初始数据
    initial_tag_data: {
      handler: function(val) {
      },
      deep: true,
    },
  },
  data(){
    return {
      input_list: [], // 输入的标签
      input: '',
      input_on: false,
      tag_list: [], // 默认已选标签
      append_tag_list: [], // 追加的标签数据,
      all_tag_list: [], // 全部标签

    }
  },
  methods:{
    /**
     * 输入新标签
     */
    inputKeyup(){
      let keyCode = event.keyCode; // 刚按下的keycode
      let input_list = this.$tools.deepClone(this.input_list);
      let append_tag_list = this.$tools.deepClone(this.append_tag_list);
      let append_unit = null;
      /**
       * keyCode
       * Backspace => 8
       */
      if(keyCode==8 && this.input == ''){
        // 删除标签
        let del_unit = input_list[input_list.length - 1];
        this.deleteTagHandler(del_unit);
        return;
      }else if (keyCode== 13 && this.input != ''){
        // Enter
        // // console.log(13,this.input)
        this.input = this.input.trim(); //清除空格
        let canInput  = this.judgeRepeat(this.input);
        if(canInput){
          append_unit = {tagName:this.input, from: 'input'};
        }else{
          this.input = '';
          return;
        }
       
      }else if(this.input !=''){
        // 添加标签
        this.input = this.input.trim(); //清除空格
        let index = 0;
        if(this.input.indexOf(',') != -1 ){
          index = this.input.indexOf(',');
        }else if (this.input.indexOf('，') != -1){
          index = this.input.indexOf('，');
        }
        if(index != 0){
          let key = this.input.substring(0,index);
          let canInput  = this.judgeRepeat(key);
          if(canInput){
            append_unit = {tagName:key, from: 'input'};
          }else{
            this.input = '';
            return;
          }
        
          
        }else{
          return;
        }
      }
      if(append_unit){
        input_list.push(append_unit);
        append_tag_list.push(append_unit);
        this.linkageInputHandler(append_unit);
      }
      
      this.$set(this, 'input_list', input_list);
      this.$set(this, 'append_tag_list', append_tag_list);
      this.input = '';
    },
    /**
     * 添加标签
     */
    async addTags(){
      let {append_tag_list, input_list, tag_list, template_addtag_unit_data} = this;

      let deletetagIds = '';
      let addtags = '';
      append_tag_list.forEach(item => {
        if(addtags != ''){
          addtags += ',';
        }
        addtags += item.tagName;
      })
      /**获取删除id */
      if(template_addtag_unit_data.tags){
        template_addtag_unit_data.tags.forEach(item=>{
          let isIn = tag_list.find(subitem => {
            return subitem.id == item.id;
          })
          if(!isIn){
            if(deletetagIds != ''){
              deletetagIds += ',';
            }
            deletetagIds+= item.id;
          }
        })
      }
      let data = {
        taskId: this.template_addtag_unit_data.id,
        addtags,
        deletetagIds,
      }
      let res = await this.$http.request('/projectTask/doTags', data);
      this.$emit('closeTagBox');
      this.$emit('refresh');
      // let tags = '';
      // for(let i in this.input_list){
      //   tags += this.input_list[i].tagName;
      //   if(i != this.input_list.length -1){
      //     tags += ',';
      //   }
      // }
      // if(tags!=''){
      //   let data={
      //     taskId: this.template_addtag_unit_data.id,
      //     tags,
      //   }
      //   let res = await this.$http.request('/projectTask/addTags', data);
      //   this.$emit('closeTagBox');
      // }else{
      //   this.$toast({icon:'none',title:'提示',desc:'请输入标签',duration:600})
      // }
    },
    /**
     * 删除输入的标签 
     */
    delInputList( index){
      let input_list = this.$tools.deepClone(this.input_list);
      input_list.splice(index, 1);
      this.$set(this, 'input_list', input_list);
    },
    /**
     * 联动 用于输入
     */
    linkageInputHandler(append_unit){
      let {all_tag_list, tag_list, append_tag_list} = this;
      let {tagName} = append_unit;
      let aim_index = all_tag_list.findIndex( item => {
        return item.tagName == tagName;
      })
      if(aim_index != -1){
        this.$set(this.all_tag_list[aim_index], 'select', true);
      }

    },
    /**
     * 联动 用于所有标签点击增加
     */
    linkageClickHandler(item, index){
      if(!item.select){
        let {all_tag_list, tag_list, append_tag_list} = this;
        let {tagName } = item;
        append_tag_list.push(item);
        this.$set(this.all_tag_list[index], 'select', true);
        this.$set(this, 'append_tag_list', append_tag_list);
      }
    },
    /**删除标签 */
    deleteTagHandler(item){
      let {tagName, from} = item;
      let {all_tag_list, tag_list, append_tag_list, input_list} = this;
      if(from == 'input'){
        // 删除input列表
        let input_index = input_list.findIndex(item=>{
          return item.tagName == tagName;
        })
        input_list.splice(input_index, 1);
        this.$set(this, 'input_list', input_list);
        
      }else{ 
        // 删除初始选中列表
        let tag_index = tag_list.findIndex(item=>{
          return item.tagName == tagName;
        })
        if(tag_index != -1){
          tag_list.splice(tag_index, 1);
          this.$set(this, 'tag_list', tag_list);
        }
      }
      // 所有标签移除
      let all_index = all_tag_list.findIndex(item=>{
        return item.tagName == tagName;
      })
      if(all_index != -1){
        this.$set(this.all_tag_list[all_index], 'select', false);
      }
      // 追加列表移除
      let append_index = append_tag_list.findIndex(item=>{
        return item.tagName == tagName;
      })
      if(append_index != -1){
        append_tag_list.splice(append_index, 1);
        this.$set(this, 'append_tag_list', append_tag_list);
      }
    },
    init(){
      this.input = '';
      this.input_on = false;
      this.$set(this, 'input_list', []);
    },
    /** 判断重复输入 */
    judgeRepeat(input){
      let {input_list, tag_list } = this;
      let isInInput = input_list.find(item => {
        return item.tagName == input
      })
      let isInTag = tag_list.find( item => {
        return item.tagName == input
      })
      if(isInInput || isInTag){
        return false
      }else{
        return true
      }
    },
  },
  mounted(){
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.template_add_tags_wrapper{
  @include flex;
  flex-direction: column;
  overflow: hidden;
  width:602px;
  height:768px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius:4px;
}
.title_group{
  @include flex;
  @include bbox;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 84px;
  border-bottom: 2px solid $border_color;
  .title{
    font-size:18px;
    
    font-weight:400;
    color:rgba(51,51,51,1);
  }
  .close{
    width: 20px;
    height: 20px;
    position: absolute;
    top:50%;
    right: 20px;
    margin-top: -10px;
    background: {
      image: url(~@/assets/images/common/close.png);
      size: 12px 12px;
      position: center;
      repeat: no-repeat;
    }
  }
}
.fun_group{
  @include flex;
  @include bbox;
  justify-content: center;
  flex-shrink: 0;
  border-top: 2px solid $border_color;
  height: 90px;
  width: 100%;
  .fun{
    line-height: 50px;
    font-size: 18px;
    
    font-weight: 400;
    color: $main_blue;
    width: 160px;
    border-radius: 4px;
    text-align: center;
    background: rgba(0, 84, 167, 0.1);
    margin: 0 20px;
    &.sure {
      background-color: $main_blue;
      color: #fff;
    }
  }
}
.content_group{
  @include flex;
  @include bbox;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  width:100%;
  flex:1;
  .sticky{
    position: sticky;
    top: 0;
  }
  .fixed_display{
    flex-shrink: 0;
    width: 100%;
  }
  .search_group{
    @include bbox;
    width: 100%;
    padding: 30px 36px 16px;
    .search_box{
      @include flex;
      @include bbox;
      padding:0 30px;
      width: 100%;
      height:40px;
      border-radius:20px;
      background:rgba(243,243,243,1);
      &.on{
        border:1px solid rgba(187,187,187,1);
        background: #fff;
      }
      .search_tag{
        @include bbox;
        padding: 0 20px;
        line-height:28px;
        height:30px;
        background:rgba(229,238,246,1);
        border:1px solid rgba(169,194,219,1);
        border-radius:15px;
        font-size:12px;
        
        font-weight:400;
        color:rgba(0,84,167,1);
        margin-right: 10px;
      }
      .search{
        @include placeholder(#999);
        border:none;
        outline: none;
        background-color: transparent;
        font-size:14px;
        
        font-weight:400;
        color:#999;
        width:100%;
        flex: 1;
      }
    }

  }
  .tag_group{
    @include bbox;
    padding: {
      left: 36px;
      right: 20px;
      // top: 29px;
      top: 13px;
    }
    width: 100%;
    .tag_title{
      font-size:14px;
      
      font-weight:400;
      color:rgba(153,153,153,1);
      padding: {
        bottom: 19px;
      }
    }
    .tag_box{
      @include flex;
      flex-wrap: wrap;
      width: 100%;
      .tag{
        @include bbox;
        position: relative;
        line-height:32px;
        height: 36px;
        padding: 0 20px;
        background:rgba(245,248,252,1);
        border:2px solid rgba(238,238,238,1);
        border-radius:18px;
        margin: {
          bottom: 16px;
          right: 16px;
        }
        font-size:12px;
        
        font-weight:400;
        color:#333;
        &.on{
          background:rgba(229,238,246,1);
          border:2px solid rgba(169,194,219,1);
          color: $main_blue;
        }
        &:hover{
          border:2px solid rgba(169,194,219,1);
        }
        .close{
          cursor: pointer;
          position: absolute;
          right:-4px;
          top:-4px;
          width: 14px;
          height: 14px;
          background: {
            image: url(~@/assets/images/common/clear_icon.png);
            size: 100%;
          }
          z-index: 3;
        }
      }
    }
  }
  .all_group{
    @include flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-y: hidden;
    align-items: flex-start;
    .tag_title{
      flex-shrink: 0;
    }
    .tag_box{
      @include scrollbar;
      overflow-y: auto;
      height: 100%;
      flex: 1;
    }
  }
}

</style>
