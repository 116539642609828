<template>
  <div
    class="v_collect_index d-flex flex-column"
  >
    <operation-bar
      class="flex-shrink-0"
      :filter_list="filter_list"
      :folder_list="folder_list"
      :file_list="file_list"
      :switch_select_folder="switch_select_folder"
      :switch_select_file="switch_select_file"
      :current_folder="current_folder"
      @handleCreateFolder="handleCreateFolder"
      @handleChangeSearch="handleChangeSearch"
      :switch_select="switch_select_folder || switch_select_file"
      @handleCancelSelect="handleCancelSelect"
      @handleRemove="handleRemove"
      @handleGetFolderList="handleGetFolderList"
      @handleGetFileList="handleGetFileList"
      @handleRemoveSelectedFile="handleRemoveSelectedFile"
      @handleRemoveSelectedFolder="handleRemoveSelectedFolder"
      @handleGetCurrentFolder="handleGetCurrentFolder"
      ref="operation"
    ></operation-bar>
    <main-component
      class="main_group"
      ref="main"
      :switch_select_folder="switch_select_folder"
      :switch_select_file="switch_select_file"
      @handleStartSelectFolder="handleStartSelectFolder"
      @handleStartSelectFile="handleStartSelectFile"
      @handleCancelSelect="handleCancelSelect"
    ></main-component>
  </div>
</template>

<script>
import OperationBar from '@/components/Collect/OperationBar.vue'
import MainComponent from '@/components/Collect/Main.vue'
export default {
  components: {
    OperationBar,
    MainComponent,
  },
  watch:{
  },
  data(){
    return{
      switch_select_folder: false,
      switch_select_file: false,
      filter_list: undefined,
      folder_list: undefined,
      file_list: undefined,
      current_folder: undefined,
    }
  },
  mounted(){
  },
  methods: {
    handleCreateFolder(p_folder){
      if(p_folder){
        this.$refs.main.handleCreateFolder(p_folder)
      }
    },
    handleChangeSearch(search){
      this.$refs.main.changeSearch(search)
    },
    handleStartSelectFile(){
      this.switch_select_file = true
    },
    handleStartSelectFolder(){
      this.switch_select_folder = true
    },
    handleCancelSelect(){
      this.switch_select_folder = false
      this.switch_select_file = false
    },
    handleRemove(aim){
      this.$refs.main.handleRemove(aim)
    },
    handleGetFolderList(){
      const folder_list = this.$refs.main.folder_list
      this.folder_list = folder_list
      if(folder_list&&folder_list.length){
        const filter_list = folder_list.filter(unit=>{
          return unit.cf && unit.cf.selected
        })
        this.filter_list = filter_list
        return
      }
      this.filter_list = undefined
    },
    handleGetFileList(){
      const file_list = this.$refs.main.file_list
      this.file_list = file_list
    },
    handleGetCurrentFolder(){
      console.log(this.$refs.main.current_folder, this.$refs.main)
      const current_folder = this.$refs.main.current_folder
      this.current_folder = current_folder
    },
    handleRemoveSelectedFile(){
      this.$refs.main.handleRemoveSelectedFile()
    },
    handleRemoveSelectedFolder(){
      this.$refs.main.handleRemoveSelectedFolder()
    }

  }
}
</script>

<style lang="scss" scoped>
.v_collect_index{
  width: 100%;
  height: 100%;
  background-color: #fff;
  .main_group{
    flex: 1;
    width: 100%;
    overflow: auto;
  }
  .main_wrapper{
    width: 100%;
  }
}
</style>
