<!--
 * @Author       : Hugo
 * @Date         : 2021-04-25 10:42:58
 * @LastEditTime: 2022-05-20 00:17:23
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/FunTemplate/Custom/Layout.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div class="custom_page">
    <view-collect></view-collect>
    <template
      v-if="0"
    >
      <div class="fun_group">
        <button
          class="button cancel"
          v-if="status_cancel_btn"
          v-preventReClick="500"
          @click="doCancel();"
        >
          <div class="text">取消</div>
        </button>
        <button
          class="button add_task"
          v-if="status_add_task_btn"
          v-preventReClick="500"
          @click="addTask();"
        >
          <img src="~@/assets/images/custom/add.png" alt="" class="icon">
          <div class="text">添加任务</div>
        </button>
        <button class="create button"
          @click="createNewFolder"
          v-preventReClick="500"
        >
          <img src="~@/assets/images/custom/create.png" alt="" class="icon">
          <div class="text">新建分组</div>
        </button>
        <div class="type_group flex"
          v-if="0"
        >
          <button class="button view"
            :class="{on: type=='view'}"
            @click="changeType('view')"
          >
            <img src="~@/assets/images/custom/type_view.png" alt="" class="icon">
            <div class="text">视图</div>
          </button>
          <button class="button list"
            :class="{on: type=='list'}"
            @click="changeType('list')"
          >
            <img src="~@/assets/images/custom/type_list.png" alt="" class="icon">
            <div class="text">列表</div>
          </button>
        </div>
        <div class="search_group flex"
          @click="openSearch();"
        >
          <img src="~@/assets/images/custom/search.png" alt="" class="icon cp">
          <input type="text" class="input search"
            :class="{on:switch_search}"
            v-model="search_keyword"
            @blur="searchBlur();"
            ref="search"
            placeholder="输入内容，Enter进行搜索"
            @keydown.enter="doSearch()"
          >
          <img src="~@/assets/images/common/clear_icon.png" alt="" class="clear cp"
            v-show="search_keyword!=''"
            @click="doClearSearch();"
          >
        </div>
    
        <el-popover
          placement="right-start"
          width="400"
          trigger="click"
          :visible-arrow="false"
          popper-class="custom_folder_menu_popover"
          v-model="switch_popover"
        >
          <div class="menu_group">
            <div class="menu_list_group">
              <div class="item un_sel cp"
                @click="openMovingPath();"
              >
                <img src="~@/assets/images/custom/menu/move.png" alt="" class="icon">
                <div class="text">移动至</div>
                <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
              </div>
              <div class="item un_sel cp"
                v-if="switch_custom_multiple_folder"
                @click="deleteMultipleFolder();"
              >
                <img src="~@/assets/images/custom/menu/delete.png" alt="" class="icon">
                <div class="text">删除此分组</div>
                <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
              </div>
              <div class="item un_sel cp"
                v-if="switch_custom_multiple_file"
                @click="deleteMultipleFile();"
              >
                <img src="~@/assets/images/custom/menu/delete.png" alt="" class="icon">
                <div class="text">移出自定义</div>
                <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
              </div>
            </div>
          </div>
          <button class="more_btn button flex" slot="reference"
            v-show="status_more_btn"
          >
            <div class="text">更多</div>
            <img class="icon"
              src="~@/assets/images/custom/more_open.png"
            >
          </button>
        </el-popover>
        <div class="spacer">
          <transition name="fade">
            <moving-path
              v-if="switch_moving_path && status_more_btn"
              class="moving_path"
            ></moving-path>
          </transition>
        </div>
        
      </div>
      <div class="container flex">
        <div class="path_group"
          v-if="status_breadcrumb"
        >
          <overlay-scrollbars
            class="overlay_container"
            ref="overlay"
          >
            <div class="path_overlay_group flex">
              <div class="text top cp"
                @click="$router.push({name: 'fun_template_my', params:{type: 'custom'}})"
              >全部分组</div>
              <breadcrumb
                :data="breadcrumb"
                :current="true"
              ></breadcrumb>
            </div>
            
          </overlay-scrollbars>
          
          
        </div>
        <views
          v-show="type=='view'"
          ref="views"
        ></views>
        <list
          v-show="type=='list'"
        ></list>


      </div>
    </template>
  </div>
  
</template>

<script>
import ViewCollect from '@/views/Collect/index.vue'
import Views from '@/components/FunTemplate/Custom/View.vue';
import List from '@/components/FunTemplate/Custom/List.vue';
import Breadcrumb from '@/components/FunTemplate/Custom/Breadcrumb.vue';
import MovingPath from '@/components/Common/MovingPath/Container.vue';
export default {
  components: {
    Views,
    List,
    Breadcrumb,
    MovingPath,
    ViewCollect,
  },
  data() {
    return {
      switch_search: false,
      search_keyword: '',
      type: 'view',
      breadcrumb: {},
      switch_popover: false,
    }
  },
  computed: {
    // ********  VUEX
    switch_custom_multiple_folder(){
      return this.$store.state.switch_custom_multiple_folder;
    },
    switch_custom_multiple_file(){
      return this.$store.state.switch_custom_multiple_file;
    },
    switch_moving_path(){
      return this.$store.state.switch_moving_path;
    },
    // ********  IS
    // 是否是顶级目录
    is_top(){
      const {folderid} = this.$route.params;
      return Boolean(!folderid); 
    },
    // ********  STATUS
    // 面包屑导航
    status_breadcrumb(){
      const {is_top} = this;
      return !is_top;
    },
    // 添加任务按钮
    status_add_task_btn(){
      const {is_top} = this;
      return !is_top;
    },
    status_cancel_btn(){
      const {switch_custom_multiple_folder, switch_custom_multiple_file} = this;
      if(switch_custom_multiple_folder){
        return true;
      }
      if(switch_custom_multiple_file){
        return true;
      }
      return false;
    },
    status_more_btn(){
      const {switch_custom_multiple_folder, switch_custom_multiple_file} = this;
      if(switch_custom_multiple_folder){
        return true;
      }
      if(switch_custom_multiple_file){
        return true;
      }
      return false;
    },
  },
  watch: {
    '$route.params.folderid': function(val){
      if(val){
        this.init();
      }
    },
    search_keyword: function(val){
      if(val == ''){
        this.doSearch();
      }
    },
  },
  methods: {
    openMovingPath(){
      const {foldersid, filesid} = this.$api.custom.getMovingPathId();
      if(foldersid == '' && filesid == ''){
        this.$notice({desc: '没有选择的任务或分组'});
        this.closeMoreMenu();
        return;
      }
      this.$store.commit('set_switch_moving_path', true);
      this.closeMoreMenu();
    },
    closeMoreMenu(){
      this.switch_popover = false;
    },
    doCancel(){
      const {switch_custom_multiple_folder, switch_custom_multiple_file} = this;
      const {commit} = this.$store;
      if(switch_custom_multiple_folder){
        commit('set_switch_custom_multiple_folder', false);
      }
      if(switch_custom_multiple_file){
        commit('set_switch_custom_multiple_file', false);
      }
    },
    /**
     * @description: 清除搜索内容
     * @param {*}
     * @return {*}
     */
    doClearSearch(){
      this.search_keyword = '';
      this.$refs.search.focus();
      this.doSearch();
    },
    /**
     * @description: 搜索失焦
     * @param {*}
     * @return {*}
     */
    searchBlur(){
      const {search_keyword} = this;
      if(search_keyword == ''){
        this.switch_search = false;
      }
    },
    /**
     * @description: 打开搜索
     * @param {*}
     * @return {*}
     */
    openSearch(){
      const {switch_search} = this;
      if(!switch_search){
        this.switch_search= true;
        this.$nextTick(function(){
          this.$refs.search.focus();
        })
      }
    },
    /**
     * @description:搜索 
     * @param {*}
     * @return {*}
     */
    doSearch(){
      const {search_keyword} = this;
      this.$refs.views.doSearch({keyword: search_keyword});
    },
    /**
     * @description: 改变视图类型
     * @param {*} type
     * @return {*}
     */
    changeType(type){
      this.type = type;
    },
    /**
     * @description: 创建新文件夹
     * @param {*}
     * @return {*}
     */
    createNewFolder(){
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return false
      }
      this.$api.custom.triggerCreateNewFolder();
    },
    /**
     * @description: 添加任务
     * @param {*}
     * @return {*}
     */
    addTask(){
      // const membership_privileges = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(!membership_privileges){
      //   return ;
      // }
      this.$api.custom.triggerAddTask();
    },
    /**
     * @description: 获取面包屑导航
     * @param {*}
     * @return {*}
     */
    async getBreadcurmb(){
      const {folderid} = this.$route.params;
      let fId = 0;
      if(folderid){
        fId = this.$global.decodeBase64(folderid);
      }
      if(fId){
        let res = await this.$global.doPost({
          url: '/projectCustom/queryCustomFolderRoute',
          data: {fId},
          notice: '获取文件路径失败',
        })
        if(res.contents && res.contents.PFolderRoute){
          this.$set(this, 'breadcrumb', res.contents.PFolderRoute);
          this.$nextTick(function(){
            if(this.$refs.overlay){
              this.$refs.overlay.osInstance().scroll({x: '100%'});
            }
          })
        }
      }
    },
    async init(){
      this.search_keyword = '';
      this.getBreadcurmb();
      this.$api.custom.clearMultipleSelect();
    },
    /**
     * @description: 多选删除文件夹
     * @param {*}
     * @return {*}
     */
    deleteMultipleFolder(){
      // const membership_privileges = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(!membership_privileges){
      //   return ;
      // }
      const {foldersid, filesid} = this.$api.custom.getMovingPathId();
      if(foldersid == ''){
        this.$notice({desc: '未选择分组'});
        return;
      }
      this.$openConfirm({
        title:`是否删除分组?`,
        desc:'',
        sure:'确定',
        cancel:'取消',
        onSure:async ()=>{
          let data = {
            folderIds: foldersid,
          }
          let res = await this.$global.doPost({
            url: '/projectCustom/deleteFolder',
            data,
            notice: '删除失败',
          })
          if(res.message=='success'){
            
            // 重新加载文件和文件夹
            this.$store.commit('set_switch_reload_custom_folders', true);
            this.$api.custom.clearMultipleSelect();
          }
          this.closeMoreMenu();
        },
        onCancel:()=>{
          this.closeMoreMenu();
        },
      })

    },
    /**
     * @description: 移除自定义
     * @param {*}
     * @return {*}
     */
    deleteMultipleFile(){
      // const membership_privileges = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(!membership_privileges){
      //   return ;
      // }
      const {foldersid, filesid} = this.$api.custom.getMovingPathId();
      if(filesid == ''){
        this.$notice({desc: '未选择任务'});
        return;
      }
      this.$openConfirm({
        title:`是否移除任务?`,
        desc:'',
        sure:'确定',
        cancel:'取消',
        onSure:async ()=>{
          let data = {
            folderId: this.$global.decodeBase64(this.$route.params.folderid),
            deleteIds: filesid,
          }
          let res = await this.$global.doPost({
            url: '/projectCustom/addORdeleteFolderTask',
            data,
            notice: '删除失败',
          })
          if(res.message=='success'){
            
            // 重新加载文件和文件夹
            this.$store.commit('set_switch_reload_custom_files', true);
            this.$api.custom.clearMultipleSelect();
          }
          this.closeMoreMenu();
        },
        onCancel:()=>{
          this.closeMoreMenu();
        },
      })
    },
  },
  async mounted(){
    this.init();
  }
}
</script>

<style lang="scss" scoped>
// 功能边框
$fun_border: 1px solid #ccc;
// 功能按钮高度
$fun_height: 40px;
// 功能栏radius
$fun_radius: 4px;
// 功能栏按钮背景色
$fun_btn_background_color: #f8f8f8;
// 功能栏mr
$fun_mr: 20px;
.custom_page{
  @include bbox;
  @include flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // padding: 20px;
  padding-left: 20px;
}
.fun_group{
  @include bbox;
  @include flex;
  position: relative;
  z-index: 3;
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 84, 167, 0.06);
  border-radius: 10px;
  .button{
    @include bbox;
    @include flex;
    justify-content: center;
    cursor: pointer;
    background-color: $fun_btn_background_color;
  }
  .cancel{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
  }
  .text{
    @include u_sel_none;
    font-size: 14px;
    color: #666;
  }
  .add_task{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    .icon{
      margin-right: 5px;
    }
  }
  .create{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    .icon{
      margin-right: 5px;
    }
  }
  .type_group{
    @include bbox;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    margin-right: $fun_mr;
    overflow: hidden;
    .icon{
      margin-right: 5px;
    }
    .button{
      @include transition;
      height: 100%;
      &.on{
        background-color: #ddd;
      }
    }
    
  }
  .view{
    padding: {
      left: 20px;
      right: 10px;
    }
  }
  .list{
    padding: {
      left: 10px;
      right: 20px;
    }
  }
  .search_group{
    @include transition;
    padding: 0 20px;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    position: relative;
    margin-right: $fun_mr;
  }
  .search{
    @include bbox;
    @include transition;
    @include placeholder(#ccc);
    // width: 0;
    z-index: 2;
    border: none;
    font-size: 14px;
    color: #666;
    outline: none;
    line-height: 20px;
    padding-right: 0;
    // width: 200px;
    width: 0;
    margin-left: 0px;
    &.on{
      width: 200px;
      margin-left: 10px;
      padding-right: 20px;
    }
  }
  .more_btn{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    .icon{
      margin-left: 5px;
    }
  }
  .clear{
    position: absolute;
    z-index: 3;
    top:50%;
    right: 20px;
    width: 14px;
    height: 14px;
    transform: translateY(-50%);
  }
  .spacer{
    align-self: stretch;
    width:100%;
    flex: 1;
    position: relative;
    .moving_path{
      position: absolute;
      width: 100%;
      top:0;
      left:0;
      z-index: 3;
    }
  }
}
.container{
  flex-direction: column;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}
.path_group{
  @include bbox;
  padding: 0 20px;
  font-size: 14px;
  color:#666;
  flex-shrink: 0;
  width: 100%;
  .path_overlay_group{
    font-size: 14px;
    color:#666;
    width: 100%;
    padding: {
      top: 20px;
      bottom: 10px;
    }
  }
  .top{
    @include transition;
    flex-shrink: 0;
    &:hover{
      color: $main_blue;
    }
  }
}
</style>
<style scoped>
/* vue动画过渡效果设置 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
