var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_teaching_package_thumbnail bbox pa-20 cp",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          _vm.clickWrapper()
          _vm.closePopover()
        },
      },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.open_select,
            expression: "open_select",
          },
        ],
        staticClass: "select_group",
        class: { active: _vm.item.cf_selected },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.touchSelect()
          },
        },
      }),
      _c("div", { staticClass: "title bbox pr-14" }, [
        _vm._v(_vm._s(_vm.item.title)),
      ]),
      _vm.menu
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "click",
                "popper-class": "components_teaching_package_thumbnail_popover",
                "visible-arrow": false,
              },
              model: {
                value: _vm.switch_more_btn,
                callback: function ($$v) {
                  _vm.switch_more_btn = $$v
                },
                expression: "switch_more_btn",
              },
            },
            [
              _c("div", { staticClass: "menu_group bbox un_sel" }, [
                _c(
                  "div",
                  {
                    staticClass: "item cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.closePopover()
                        _vm.doShare()
                      },
                    },
                  },
                  [_c("div", { staticClass: "text" }, [_vm._v("分享")])]
                ),
                _vm.putaway_status
                  ? _c(
                      "div",
                      {
                        staticClass: "item cp",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.closePopover()
                            _vm.doPutaway()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "text" }, [
                          _vm._v("上架至商城"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.sold_out_status
                  ? _c(
                      "div",
                      {
                        staticClass: "item cp",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.closePopover()
                            _vm.doSoldOut()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "text" }, [
                          _vm._v("从商城下架"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "item cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.closePopover()
                        _vm.doDelete()
                      },
                    },
                  },
                  [_c("div", { staticClass: "text" }, [_vm._v("删除")])]
                ),
              ]),
              _c("div", {
                staticClass: "menu_btn cp",
                attrs: { slot: "reference" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$api.stopPropagation($event)
                  },
                },
                slot: "reference",
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "img_group mt-16" }, [
        _vm.item.webpic
          ? _c("img", {
              staticClass: "img",
              attrs: { src: `${_vm.$img_url}${_vm.item.webpic}`, alt: "" },
            })
          : _c(
              "div",
              { staticClass: "placeholder d-flex align-center justify-center" },
              [
                _c("div", { staticClass: "name bbox px-20" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ]),
              ]
            ),
      ]),
      _vm.sell
        ? _c(
            "div",
            {
              staticClass:
                "status_group d-flex align-center justify-space-between mt-10 un_sel",
            },
            [
              _c(
                "div",
                {
                  staticClass: "aduit_status",
                  style: {
                    color: _vm.item.getAuditStatus()
                      ? _vm.item.getAuditStatus().color
                      : "#999",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.item.getAuditStatus()
                        ? _vm.item.getAuditStatus().audit
                        : ""
                    )
                  ),
                ]
              ),
              _vm.item.showflag
                ? _c("img", {
                    staticClass: "putaway",
                    attrs: {
                      src: require("@/assets/images/task_thumb/putaway.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }