var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_collect_index d-flex flex-column" },
    [
      _c("operation-bar", {
        ref: "operation",
        staticClass: "flex-shrink-0",
        attrs: {
          filter_list: _vm.filter_list,
          folder_list: _vm.folder_list,
          file_list: _vm.file_list,
          switch_select_folder: _vm.switch_select_folder,
          switch_select_file: _vm.switch_select_file,
          current_folder: _vm.current_folder,
          switch_select: _vm.switch_select_folder || _vm.switch_select_file,
        },
        on: {
          handleCreateFolder: _vm.handleCreateFolder,
          handleChangeSearch: _vm.handleChangeSearch,
          handleCancelSelect: _vm.handleCancelSelect,
          handleRemove: _vm.handleRemove,
          handleGetFolderList: _vm.handleGetFolderList,
          handleGetFileList: _vm.handleGetFileList,
          handleRemoveSelectedFile: _vm.handleRemoveSelectedFile,
          handleRemoveSelectedFolder: _vm.handleRemoveSelectedFolder,
          handleGetCurrentFolder: _vm.handleGetCurrentFolder,
        },
      }),
      _c("main-component", {
        ref: "main",
        staticClass: "main_group",
        attrs: {
          switch_select_folder: _vm.switch_select_folder,
          switch_select_file: _vm.switch_select_file,
        },
        on: {
          handleStartSelectFolder: _vm.handleStartSelectFolder,
          handleStartSelectFile: _vm.handleStartSelectFile,
          handleCancelSelect: _vm.handleCancelSelect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }