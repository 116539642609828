<!--
 * @Author       : Hugo
 * @Date         : 2022-04-07 15:04:13
 * @LastEditTime: 2022-05-20 00:16:20
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/Collect/OperationBar.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_operation_bar flex-shrink-0 d-flex align-center bbox pl-20"
  >
    <button class="create button"
      @click.stop="touchCreate()"    
    >
      <img src="~@/assets/images/custom/create.png" alt="" class="icon">
      <div class="text">新建分组</div>
    </button>
    <div class="search_group bbox d-flex align-center"
    >
      <img src="~@/assets/images/custom/search.png" alt="" class="icon cp">
      <input type="text" class="search bbox"
        v-model="search"
        placeholder="搜索"
        @keydown.enter="handleKeydown($event)"
      >
      <img src="~@/assets/images/common/clear_icon.png" alt="" class="clear cp"
        v-show="search.trim() !== ''"
        @click.stop="clearSearch()"
      >
    </div>
    <el-popover
        placement="right-start"
        width="400"
        trigger="click"
        :visible-arrow="false"
        popper-class="c_collect_folder_unit_popover"
        v-model="switch_popover"
      >
        <div class="menu_group"
          ref="menu_group"
          v-show="!start_moving"
        >
          <div class="menu_list_group">
            <div class="item un_sel cp"
              @click="startMove()"
            >
              <img src="~@/assets/images/custom/menu/move.png" alt="" class="icon">
              <div class="text">移动至</div>
              <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
            </div>
         
            <div class="item un_sel cp"
              @click.stop="cancelCollect()"
            >
              <img src="~@/assets/images/custom/menu/delete.png" alt="" class="icon">
              <div class="text">取消收藏</div>
              <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
            </div>
          </div>
        </div>
        <div class="moving_path_wrapper"
          v-if="start_moving"
          :style="{width: `${moving_path_width}px`, 'z-index': moving_path_z_index}"
        >
          <moving-path
            v-if="start_moving"
            :mask="false"
            :multiple="false"
            :current_folder="current_folder"
            type="folder"
            :filter_list="filter_list"
            :max_height="String(moving_max_height)"
            @handleClose="doCloseMenu"
            @handleRemove="handleRemove"
            @handleSure="handleSure"
          ></moving-path>
        </div>
        <button class="more_btn button flex" slot="reference"
          v-show="switch_select"
        >
          <div class="text">更多</div>
          <img class="icon"
            src="~@/assets/images/custom/more_open.png"
          >
        </button>
    </el-popover>
    <button
      class="button cancel"
      v-if="switch_select"
      @click="doCancel();"
    >
      <div class="text">取消多选</div>
    </button>
  </div>
</template>

<script>
import store from '@/store'
import global from '@/api/global.js'
import {PFolder} from '@/types'
import MovingPath from '@/components/Common/MovingPath/Container.vue'
export default {
  components: {
    MovingPath,
  },
  computed: {
    collect_nav(){
      return this.$store.state.collect_nav
    }
  },
  props:{
    switch_select: {
      type: Boolean,
      default: false,
    },
    filter_list: {
      type: Array,
      default: undefined,
    },
    folder_list: {
      type: Array,
      default: undefined,
    },
    file_list: {
      type: Array,
      default: undefined,
    },
    switch_select_folder: {
      type: Boolean,
      default: undefined,
    },
    switch_select_file: {
      type: Boolean,
      default: undefined,
    },
    current_folder: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    collect_nav: function(){
      this.search = ''
    },
    switch_popover:function(val){
      if(!val){
        this.start_moving = false
      }

    }
  },
  data(){
    return{
      search: '',
      switch_popover:false,
      start_moving: false,
      moving_path_width: 0,
      moving_path_z_index: 0,
      moving_max_height: '451',
      // filter_list: undefined,
    }
  },
  methods: {
    touchCreate(){
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return false
      }
      const id = this.getFolderId()
      this.$openPrompt({
        title:'新建分组',
        placeholder: '',
        hint: '请输入分组名',
        sure: '',
        cancel: '',
        onSure: async ()=>{
          const {state} = store
          const {utils_prompt} = state
          const res = await global.doPost({
            url: '/projectCustom/createFolder',
            data: {
              fId: id,
              name: utils_prompt.trim()
            }
          })
          if(res.message === 'success'){
            if(res.contents && res.contents.PFolder){
              this.$notice({desc:"创建成功", type: 'success'})
              this.$emit('handleCreateFolder', new PFolder(res.contents.PFolder))
            }
            // this.handleCreateFolder()
          }
          return

        },
        onCancel: ()=>{},
      })
    },
    getFolderId(){
      const {$route} = this
      const {params} = $route
      if(params && params.folderid){
        return params.folderid
      }
      return 0
    },
    handleKeydown(e){
      const is_chinese = this.$api.input.handleKeydownChinese(e);
      if(is_chinese){
        return;
      }
      const {search} = this
      this.$emit('handleChangeSearch', search.trim())
    },
    clearSearch(){
      this.search = ''
      this.$emit('handleChangeSearch', '')
    },
    doCancel(){
      this.$emit('handleCancelSelect')
    },
    startMove(){
      const {switch_select_folder} = this
      this.$emit('handleGetCurrentFolder')
      if(switch_select_folder){
        this.$emit('handleGetFolderList')
      }
      
      this.start_moving = true;
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const rect = this.$refs.menu_group.getBoundingClientRect();
      const left = rect.left;
      const top = rect.top;
      // 计算记录最右边宽度 有20边距
      const width = clientWidth - left - 20;
      // 计算记录最下边宽度 有20边距
      const height = clientHeight - top -20;
      this.moving_max_height = (height > 451) ? '451' : height;
      this.moving_path_width = width,
      this.moving_path_z_index = this.$utils.getZIndex();
      // this.switch_moving_path = true;
      this.$store.commit('set_switch_moving_path', true);
    },
    handleRemove(){

    },
    handleSure(aim){
      this.$emit('handleRemove', aim)
    },
    doCloseMenu(){
      this.switch_popover = false
    },
    cancelCollect(){
      this.doCloseMenu()
      const {switch_select_folder, switch_select_file} = this
      console.log({switch_select_folder, switch_select_file})
      if(switch_select_folder){
        this.$emit('handleGetFolderList')
      }
      if(switch_select_file){
        this.$emit('handleGetFileList')
      }
      this.$nextTick(async function(){
        const {folder_list, file_list} = this
        console.log({folder_list, file_list})
        if(switch_select_folder){
          const {folder_list} = this
          let folderIds = ''
          if(folder_list && folder_list.length){
            folder_list.forEach(item=>{
              if(item.cf && item.cf.selected){
                console.log(item, item.id)
                folderIds += `${folderIds?',':''}${item.id}`
              }
            })
          }
          const res = await global.doPost({
            url: '/projectCustom/deleteFolder',
            data: {folderIds}
          })
          if(res.message === 'success'){
            this.$notice({desc: '移除成功'})
            this.$emit('handleRemoveSelectedFolder')
          }
        }
        if(switch_select_file){
          const {file_list} = this
          let deleteIds = ''
          if(file_list && file_list.length){
            file_list.forEach(item=>{
              if(item.cf && item.cf.selected && item.collectionflag){
                deleteIds += `${deleteIds?',':''}${item.id}`
              }
            })
          }
          const res = await global.doPost({
            url: '/projectCustom/deleteFolderTask',
            data: {deleteIds}
          })
          if(res.message === 'success'){
            this.$notice({desc: '移除成功'})
            this.$emit('handleRemoveSelectedFile')
          }
        }
          
        this.doCancel()
      })
    }
  }
  
}
</script>

<style lang="scss" scoped>
// 功能边框
$fun_border: 1px solid #ccc;
// 功能按钮高度
$fun_height: 40px;
// 功能栏radius
$fun_radius: 4px;
// 功能栏mr
$fun_mr: 20px;
// 功能栏按钮背景色
$fun_btn_background_color: #f8f8f8;
.c_collect_operation_bar{
  height: 70px;
  border-bottom: 1px solid #f4f4f4;
  width: 100%;
  .search{
    position: relative;
    &::before{
      content: 'aaa';
      display: block;
      position: absolute;
      top:0;
      left:0;
    }
  }
  .button{
    @include bbox;
    @include flex;
    justify-content: center;
    cursor: pointer;
    background-color: $fun_btn_background_color;
  }
  .cancel{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
  }
  .text{
    @include u_sel_none;
    font-size: 14px;
    color: #666;
  }
  .add_task{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    .icon{
      margin-right: 5px;
    }
  }
  .create{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    .icon{
      margin-right: 5px;
    }
  }
  .type_group{
    @include bbox;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    margin-right: $fun_mr;
    overflow: hidden;
    .icon{
      margin-right: 5px;
    }
    .button{
      @include transition;
      height: 100%;
      &.on{
        background-color: #ddd;
      }
    }
    
  }
  .view{
    padding: {
      left: 20px;
      right: 10px;
    }
  }
  .list{
    padding: {
      left: 10px;
      right: 20px;
    }
  }
  .search_group{
    @include transition;
    padding: 0 20px;
    // border: $fun_border;
    background-color: #f0f0f0;
    height: $fun_height;
    border-radius: $fun_radius;
    position: relative;
    margin-right: $fun_mr;
  }
  .search{
    @include bbox;
    @include transition;
    @include placeholder(#999);
    // width: 0;
    z-index: 2;
    border: none;
    font-size: 14px;
    color: #666;
    outline: none;
    line-height: 20px;
    padding-right: 0;
    width: 200px;
    // width: 0;
    margin-left: 0px;
    background-color: transparent;
    padding-left: 5px;
    &.on{
      width: 200px;
      margin-left: 10px;
      padding-right: 20px;
    }
    
  }
  .more_btn{
    padding: 0 20px;
    margin-right: $fun_mr;
    border: $fun_border;
    height: $fun_height;
    border-radius: $fun_radius;
    .icon{
      margin-left: 5px;
    }
  }
  .clear{
    position: absolute;
    z-index: 3;
    top:50%;
    right: 20px;
    width: 14px;
    height: 14px;
    transform: translateY(-50%);
  }
  .spacer{
    align-self: stretch;
    width:100%;
    flex: 1;
    position: relative;
    .moving_path{
      position: absolute;
      width: 100%;
      top:0;
      left:0;
      z-index: 3;
    }
  }
}
</style>
