var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_collect_operation_bar flex-shrink-0 d-flex align-center bbox pl-20",
    },
    [
      _c(
        "button",
        {
          staticClass: "create button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchCreate()
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/images/custom/create.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "text" }, [_vm._v("新建分组")]),
        ]
      ),
      _c("div", { staticClass: "search_group bbox d-flex align-center" }, [
        _c("img", {
          staticClass: "icon cp",
          attrs: { src: require("@/assets/images/custom/search.png"), alt: "" },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          staticClass: "search bbox",
          attrs: { type: "text", placeholder: "搜索" },
          domProps: { value: _vm.search },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleKeydown($event)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
          },
        }),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.search.trim() !== "",
              expression: "search.trim() !== ''",
            },
          ],
          staticClass: "clear cp",
          attrs: {
            src: require("@/assets/images/common/clear_icon.png"),
            alt: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.clearSearch()
            },
          },
        }),
      ]),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "right-start",
            width: "400",
            trigger: "click",
            "visible-arrow": false,
            "popper-class": "c_collect_folder_unit_popover",
          },
          model: {
            value: _vm.switch_popover,
            callback: function ($$v) {
              _vm.switch_popover = $$v
            },
            expression: "switch_popover",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.start_moving,
                  expression: "!start_moving",
                },
              ],
              ref: "menu_group",
              staticClass: "menu_group",
            },
            [
              _c("div", { staticClass: "menu_list_group" }, [
                _c(
                  "div",
                  {
                    staticClass: "item un_sel cp",
                    on: {
                      click: function ($event) {
                        return _vm.startMove()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require("@/assets/images/custom/menu/move.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [_vm._v("移动至")]),
                    _c("img", {
                      staticClass: "arrow",
                      attrs: {
                        src: require("@/assets/images/custom/menu/arrow.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item un_sel cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.cancelCollect()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require("@/assets/images/custom/menu/delete.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [_vm._v("取消收藏")]),
                    _c("img", {
                      staticClass: "arrow",
                      attrs: {
                        src: require("@/assets/images/custom/menu/arrow.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm.start_moving
            ? _c(
                "div",
                {
                  staticClass: "moving_path_wrapper",
                  style: {
                    width: `${_vm.moving_path_width}px`,
                    "z-index": _vm.moving_path_z_index,
                  },
                },
                [
                  _vm.start_moving
                    ? _c("moving-path", {
                        attrs: {
                          mask: false,
                          multiple: false,
                          current_folder: _vm.current_folder,
                          type: "folder",
                          filter_list: _vm.filter_list,
                          max_height: String(_vm.moving_max_height),
                        },
                        on: {
                          handleClose: _vm.doCloseMenu,
                          handleRemove: _vm.handleRemove,
                          handleSure: _vm.handleSure,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switch_select,
                  expression: "switch_select",
                },
              ],
              staticClass: "more_btn button flex",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("div", { staticClass: "text" }, [_vm._v("更多")]),
              _c("img", {
                staticClass: "icon",
                attrs: { src: require("@/assets/images/custom/more_open.png") },
              }),
            ]
          ),
        ]
      ),
      _vm.switch_select
        ? _c(
            "button",
            {
              staticClass: "button cancel",
              on: {
                click: function ($event) {
                  return _vm.doCancel()
                },
              },
            },
            [_c("div", { staticClass: "text" }, [_vm._v("取消多选")])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }