<!--
 * @Author       : Hugo
 * @Date         : 2022-01-05 15:31:06
 * @LastEditTime: 2022-05-17 18:06:47
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/TeachingPackage/Thumbnail.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_teaching_package_thumbnail bbox pa-20 cp"
    @click.stop="clickWrapper();closePopover();"
  >
    <div class="select_group"
      v-show="open_select"
      :class="{active: item.cf_selected}"
      @click.stop="touchSelect()"
    ></div>
    <div class="title bbox pr-14">{{item.title}}</div>
    <el-popover
      placement="bottom-end"
      trigger="click"
      popper-class="components_teaching_package_thumbnail_popover"
      :visible-arrow="false"
      v-model="switch_more_btn"
      v-if="menu"
    >
      <div 
        class="menu_group bbox un_sel"
      > 
        <div class="item cp"
          @click.stop="closePopover();doShare();"
        >
          <div class="text">分享</div>
        </div>
        <div class="item cp"
          v-if="putaway_status"
          @click.stop="closePopover();doPutaway();"
        >
          <div class="text">上架至商城</div>
        </div>
        <div class="item cp"
          v-if="sold_out_status"
          @click.stop="closePopover();doSoldOut();"
        >
          <div class="text">从商城下架</div>
        </div>

        <div class="item cp"
          @click.stop="closePopover();doDelete();"
        >
          <div class="text">删除</div>
        </div>
      </div>
      <div class="menu_btn cp"
        slot="reference"
        @click.stop="$api.stopPropagation($event);"
      ></div>
    </el-popover>
    <div class="img_group mt-16">
      <img
        :src="`${$img_url}${item.webpic}`" alt="" class="img"
        v-if="item.webpic"
      >
      <div class="placeholder d-flex align-center justify-center"
        v-else
      >
        <div class="name bbox px-20">{{item.title}}</div>
      </div>
    </div>
    <div class="status_group d-flex align-center justify-space-between mt-10 un_sel"
      v-if="sell"
    >
      <div class="aduit_status"
        :style="{
          color: item.getAuditStatus() ? item.getAuditStatus().color : '#999',
        }"
      >{{item.getAuditStatus() ? item.getAuditStatus().audit : ''}}</div>
      <img 
        v-if="item.showflag"
        src="~@/assets/images/task_thumb/putaway.png"
        alt=""
        class="putaway"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    menu: { // 开启菜单
      type: Boolean,
      default: false,
    },
    sell: { // 销售
      type: Boolean,
      default: false,
    },
    open_select: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 可以上架
    putaway_status() {
      const { item } = this
      const userid = Number(this.$tools.getUserId())
      const { checkstatus, showflag, signUserId } = item
      if (userid !== Number(signUserId)) {
        return false
      }
      if (Number(checkstatus) === 3 && !showflag ) {
        return true
      }
      return false

    },
    // 可以下架
    sold_out_status(){
      const { item } = this
      const userid = Number(this.$tools.getUserId())
      const { showflag, signUserId } = item;
      if (userid !== Number(signUserId)) {
        return false
      }
      if (showflag) {
        return true
      }
      return false
    }
  },
  data() {
    return {
      switch_more_btn: false,
    }
  },
  methods: {
    clickWrapper(){
      this.$emit('handleClick', this.item);
    },
    closePopover(){
      this.switch_more_btn = false;
    },
    doShare(){
      this.$emit('handleShare', this.item);
    },
    doDelete(){
      this.$emit('handleDelete', this.item);
    },
    doPutaway(){
      this.$emit('handlePutaway', this.item);
    },
    doSoldOut(){
      this.$emit('handleSoldOut', this.item);
    },
    touchSelect(){
      const {item} = this;
      const {cf_selected} = item;
      if(cf_selected){
        item.doDeselect();
      }
      if(!cf_selected){
        item.doSelect();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.components_teaching_package_thumbnail{
  width        : 280px;
  height       : 246px;
  background   : #FFFFFF;
  box-shadow   : 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border       : 1px solid #E8EEFF;
  position     : relative;
  z-index      : 2;
  .select_group{
    @include transition;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 3;
    top: -6px;
    left: -6px;
    background-image: url(~@/assets/images/task_thumb/unselected.png);
    &.active{
      background-image: url(~@/assets/images/task_thumb/selected.png);
    }
  }
  .title{
    @include single_line_intercept;
    font-size: 16px;
    color: #333333;
    line-height: 20px;
    width: 100%;
  }
  .menu_btn{
    @include background(4px, 20px);
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 0;
    background: {
      image: url(~@/assets/images/control/fun_more.png);
      // size: contain;
      position: center;
    }
  }
  .img_group{
    width: 240px;
    height: 135px;
    position: relative;
    .img{
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .placeholder{
      width: 100%;
      height: 100%;
      background-color: #aaa;
      border-radius: 4px;
      .name{
        @include single_line_intercept;
        width: 100%;
        font-size: 24px;
        color: #fff;
        text-align: center;
      }
    }
  }
  .aduit_status{
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.components_teaching_package_thumbnail_popover{
  min-width: 120px;
  padding: 0;
  .menu_group{
    .item{
      @include transition;
      box-sizing: border-box;
      padding: 0 16px;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      &:hover{
        background-color: #eee;
      }
    }
  }
  
}
</style>

