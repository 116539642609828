var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom_page" },
    [
      _c("view-collect"),
      0
        ? [
            _c(
              "div",
              { staticClass: "fun_group" },
              [
                _vm.status_cancel_btn
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick",
                            value: 500,
                            expression: "500",
                          },
                        ],
                        staticClass: "button cancel",
                        on: {
                          click: function ($event) {
                            return _vm.doCancel()
                          },
                        },
                      },
                      [_c("div", { staticClass: "text" }, [_vm._v("取消")])]
                    )
                  : _vm._e(),
                _vm.status_add_task_btn
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick",
                            value: 500,
                            expression: "500",
                          },
                        ],
                        staticClass: "button add_task",
                        on: {
                          click: function ($event) {
                            return _vm.addTask()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("@/assets/images/custom/add.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "text" }, [
                          _vm._v("添加任务"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "preventReClick",
                        rawName: "v-preventReClick",
                        value: 500,
                        expression: "500",
                      },
                    ],
                    staticClass: "create button",
                    on: { click: _vm.createNewFolder },
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require("@/assets/images/custom/create.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [_vm._v("新建分组")]),
                  ]
                ),
                0
                  ? _c("div", { staticClass: "type_group flex" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button view",
                          class: { on: _vm.type == "view" },
                          on: {
                            click: function ($event) {
                              return _vm.changeType("view")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/custom/type_view.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [_vm._v("视图")]),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button list",
                          class: { on: _vm.type == "list" },
                          on: {
                            click: function ($event) {
                              return _vm.changeType("list")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/custom/type_list.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [_vm._v("列表")]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "search_group flex",
                    on: {
                      click: function ($event) {
                        return _vm.openSearch()
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon cp",
                      attrs: {
                        src: require("@/assets/images/custom/search.png"),
                        alt: "",
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search_keyword,
                          expression: "search_keyword",
                        },
                      ],
                      ref: "search",
                      staticClass: "input search",
                      class: { on: _vm.switch_search },
                      attrs: {
                        type: "text",
                        placeholder: "输入内容，Enter进行搜索",
                      },
                      domProps: { value: _vm.search_keyword },
                      on: {
                        blur: function ($event) {
                          return _vm.searchBlur()
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.doSearch()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.search_keyword = $event.target.value
                        },
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.search_keyword != "",
                          expression: "search_keyword!=''",
                        },
                      ],
                      staticClass: "clear cp",
                      attrs: {
                        src: require("@/assets/images/common/clear_icon.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.doClearSearch()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "right-start",
                      width: "400",
                      trigger: "click",
                      "visible-arrow": false,
                      "popper-class": "custom_folder_menu_popover",
                    },
                    model: {
                      value: _vm.switch_popover,
                      callback: function ($$v) {
                        _vm.switch_popover = $$v
                      },
                      expression: "switch_popover",
                    },
                  },
                  [
                    _c("div", { staticClass: "menu_group" }, [
                      _c("div", { staticClass: "menu_list_group" }, [
                        _c(
                          "div",
                          {
                            staticClass: "item un_sel cp",
                            on: {
                              click: function ($event) {
                                return _vm.openMovingPath()
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: require("@/assets/images/custom/menu/move.png"),
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("移动至"),
                            ]),
                            _c("img", {
                              staticClass: "arrow",
                              attrs: {
                                src: require("@/assets/images/custom/menu/arrow.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm.switch_custom_multiple_folder
                          ? _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteMultipleFolder()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/delete.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("删除此分组"),
                                ]),
                                _c("img", {
                                  staticClass: "arrow",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/arrow.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.switch_custom_multiple_file
                          ? _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteMultipleFile()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/delete.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("移出自定义"),
                                ]),
                                _c("img", {
                                  staticClass: "arrow",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/arrow.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.status_more_btn,
                            expression: "status_more_btn",
                          },
                        ],
                        staticClass: "more_btn button flex",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("div", { staticClass: "text" }, [_vm._v("更多")]),
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("@/assets/images/custom/more_open.png"),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "spacer" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [
                        _vm.switch_moving_path && _vm.status_more_btn
                          ? _c("moving-path", { staticClass: "moving_path" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "container flex" },
              [
                _vm.status_breadcrumb
                  ? _c(
                      "div",
                      { staticClass: "path_group" },
                      [
                        _c(
                          "overlay-scrollbars",
                          { ref: "overlay", staticClass: "overlay_container" },
                          [
                            _c(
                              "div",
                              { staticClass: "path_overlay_group flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text top cp",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "fun_template_my",
                                          params: { type: "custom" },
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v("全部分组")]
                                ),
                                _c("breadcrumb", {
                                  attrs: {
                                    data: _vm.breadcrumb,
                                    current: true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("views", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type == "view",
                      expression: "type=='view'",
                    },
                  ],
                  ref: "views",
                }),
                _c("list", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type == "list",
                      expression: "type=='list'",
                    },
                  ],
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }